// style.js
import styled from 'styled-components';

export const Up = styled.div`
  position: fixed;
  bottom: 10px;
  right: 10px;
  background: #000;
  height: 50px;
  width: 50px;
  border-radius: 10px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

export const Link = styled.div`
  position: fixed;
  height: 50px;
  width: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #000;
  transition: transform 0.3s ease;
  transform: translateY(${props => props.show ? '0' : '60px'});
`;

export const LinkInsta = styled(Link)`
transform: translateY(${props => props.show ? '0' : '-80px'});
position: fixed;
right: 10px;
bottom: 10px;
z-index: 999;

svg{
    color: #fff;
}
`;

export const LinkWhats = styled(Link)`
transform: translateY(${props => props.show ? '0' : '-140px'});
position: fixed;
right: 10px;
bottom: 10px;
z-index: 999;

svg{
    color: #fff;
}
}
`;

export const LinkUp = styled(Link)`
transform: translateY(${props => props.show ? '0' : '-260px'});
position: fixed;
right: 10px;
bottom: 10px;
z-index: 999;

svg{
    color: #fff;
}
`;

export const LinkMail = styled(Link)`
transform: translateY(${props => props.show ? '0' : '-200px'});
position: fixed;
right: 10px;
bottom: 10px;
z-index: 999;

svg{
    color: #fff;
}
`;


export const Modal = styled.div`
  /* Estilos para o modal */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* fundo semi-transparente */
  display: flex;
  align-items: center;
  justify-content: center;


  .modal {
    /* Estilos para o conteúdo do modal */
    background-color: #fff;
    width: 50%;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }

  @media (max-width: 1020px){
    .modal{
        width: 80%;
    }

  }


  
  .close {
    /* Estilos para o botão dentro do modal */
    background-color: #000;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: .3s;
  }
  
  .close:hover {
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
  }

  .form{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 50px;
  }

  .box-input{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
  }

  .box-input input{
    width: 100%;
    padding: 6px;
    border: 1px solid #000;
    border-radius: 5px;
    
  }

  .text-area{
    height: 100px;
    border-radius: 5px;
  }


  .btn-submit {
    /* Estilos para o botão dentro do modal */
    background-color: #000;
    color: #fff;
    border: none;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: .3s;
  }
  
  .btn-submit:hover {
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
  }

  
`;


