import React from 'react';
import {Content, Container, Cnpj} from './style.js';
import { useEffect } from 'react';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import Logo from "../../assets/logo2.png";



export function Footer() {
    const currentYear = new Date().getFullYear();
    
  return (
    <>
    <Content>
        <Container>
        <img src={Logo} />
        <div className='links'>
            <div>
                    <ul>
                        <a href='#contato'><li>Entre em contato</li></a>
                        <a href=''><li>Trabalhe conosco</li></a>
                    </ul>
                </div>
                <div>
                    <ul>
                            <a href='https://wa.me/5585998186858' target='_blanck'><li>Whatsapp</li></a>
                            <a href='https://instagram.com/conjo.sa' target='_blanck'><li>Instagram</li></a>
                            <a href='mailto:contato@conjosa.com.br?subject=Contato'><li>E-mail</li></a>
                        </ul>
                </div>
                <div className='endereco'>
                    <a href='https://www.google.com/maps/dir/-3.7421056,-38.5155072/International+Trade+Center+(ITC),+Torre+2,+Sala+202.+Rua+Ary+Barroso,+70+Papicu,+Fortaleza+-+CE,+60175-705/@-3.7424149,-38.5346867,13z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x7c746325798df1b:0xcf4da6cf8fe85a02!2m2!1d-38.4734531!2d-3.7392799?entry=ttu' target='_blank'>
                        <h3>
                        International Trade Center (ITC), Torre 2, Sala 202.
                        Rua Ary Barroso, 70
                        Papicu, Fortaleza - CE, 60175-705
                    </h3>
                    </a>
                </div>

        </div>
            

        </Container>
       
    </Content>
    <Cnpj className='cnpj'>
        <h4>44.214.793/0001-17 Conjo Consultoria em Tecnologia da Informação LTDA - Copyright © {currentYear}.</h4>
    </Cnpj>
    
    </>
    
  );
}

export default Footer;
