import styled from 'styled-components';
import React from 'react';
import { useLayoutEffect } from 'react';
import {Side, Content } from './style.js';
import Laptop from '../../assets/notebook.png';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';


export function Maximize() {

  useLayoutEffect (() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.to(".notebook",{
      x: 0,
      opacity: "1",
      scale: "1",
      ScrollTrigger:{
        trigger: ".section-first",
        markers: true,
        start: "top 0px",
        end: "bottom 500px",
      }
    })

    return () => {
      gsap.killTweensOf(".notebook")
    }
   

  }, [])




  return (
    <>
    <Content id='inicio'>
        <Side>
            <h1>
                Maximize<br />
                <strong>Eficácia</strong><br />
                Digital
            </h1>
        </Side>
        <Side>
          <img className="notebook" src={Laptop} />
        </Side>
    </Content>
    </>
    
  );
}

export default Maximize;
