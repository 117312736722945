import styled from 'styled-components';



export const ContentStripe = styled.section`
height: max-content;
width: 100%;
display: flex;
justify-content: space-evenly;
align-items: center;
margin-bottom: 100px;

@media (max-width: 768px){
    flex-direction: column;
    gap: 20px;
    height: max-content;
    padding-top: 50px;
    padding-bottom: 50px;
}
`;

export const Box = styled.div`
height: 150px;
width: 300px;
background: #000;
display: flex;
border-radius: 20px;
padding: 20px;
align-items: center;
justify-content: center;
flex-direction: column;




h2{
    color: #fff;
    font-size: 20px;
}

div{
    height: 2px;
    width: 50px;
    background: #fff;
}


`;


