import { useLayoutEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Content } from './style.js';
import Form from "../Form/index.js";

export function Contact() {
  
  return (
    <>
      <Content id="contato">
        <div>
          <h2 className="contact">Contato</h2>
        </div>
        <h2 className="lets">Vamos começar!</h2>
        <Form />
      </Content>
    </>
  );
}

export default Contact;
