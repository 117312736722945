import styled from 'styled-components';
import Back from '../../assets/back1.png';
import Back2 from '../../assets/back2.png';


export const Content = styled.section`
height: max-content;
width: 100%;
display: flex;
gap: 60px;
padding-top: 100px;
padding-bottom: 100px;
overflow: hidden;



@media(max-width: 768px){
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 40px;
}

`;

export const Side = styled.div`
height: max-content;
width: 100%;
display: flex;
justify-content: center;
align-items: end;
flex-direction: column;


.tecnologia{
    background: url(${Back});
    background-position: right;
    background-size: cover;
    height: 600px;
    width: 350px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: end;
    padding: 30px;
    transform: translateX(-500px);
    transition: 0.3s ease-in-out;
}

.tecnologia h3{
    font-size: 25px;
    font-weight: 500;
    color: #343434;
}

`;

export const Side2 = styled.div`
height: max-content;
width: 100%;
display: flex;
justify-content: center;
align-items: start;
flex-direction: column;



.design{
    background: url(${Back2});
    background-position: right;
    background-size: cover;
    height: 220px;
    width: 420px;
    border-radius: 60px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: end;
    padding: 30px;
    transform: translateX(500px);
    transition: .3s ease-in-out;
}

.design h3{
    color: #fff;
    font-weight: 500;
    font-size: 25px;

}

.about h2{
    font-size: 60px;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 0;


}



.our{
    font-family: 'Poppins', sans-serif;
    -webkit-text-stroke: 2px black;
    color: transparent;
}

.about h4{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 20px;
    width: 70%;
    text-align: justify;
}

.shadow{
    color: #747474;
    -webkit-text-stroke: 0px black;
    font-weight: 400;
}

@media (max-width: 768px){
    .about{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
`;



