import React, { useState } from 'react';
import { Header, ImgLogo, SideMenu } from './style.js';
import Logo from '../../assets/Logo.png';
import { Squeeze as Hamburger } from 'hamburger-react';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import InstagramIcon from '@mui/icons-material/Instagram';

export default function Menu() {
  const [isOpen, setOpen] = useState(false);

  const handleCloseMenu = () => {
    setOpen(false);
  };

  return (
    <>
      <Header>
        <div>
          <ImgLogo src={Logo}></ImgLogo>
        </div>
        <div>
          <Hamburger toggled={isOpen} toggle={setOpen} color="#000" size={40}/>
        </div>
        <div>
          <ul className='icons'>
            <a href='https://wa.me/5585998186858' target='_blanck'><WhatsAppIcon></WhatsAppIcon></a>
            <a href='mailto:contato@conjosa.com.br?subject=Contato'><MailOutlineIcon></MailOutlineIcon></a>
            <a href='https://instagram.com/conjo.sa' target='_blanck'><InstagramIcon></InstagramIcon></a>
          </ul>
        </div>
      </Header>
      <SideMenu isOpen={isOpen} onClick={handleCloseMenu} className="menu">
        <ul>
          <a href='#inicio'><li>Início</li></a>
          <a href='#about'><li>Sobre nós</li></a>
          <a href='#work'><li>Portifólio</li></a>
          <a href='#contato'><li>Contato</li></a>
        </ul>
      </SideMenu>
    </>
  );
}

