import React from 'react';
import {Side, Side2, Content} from './style.js';
import { useEffect } from 'react';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';





export function About() {

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        gsap.to(".design", {
            scrollTrigger: {
              trigger: ".design",
              scrub: true,
              start: "top center",
              end: "top 50px "
            },
            x: 0,
        });

        gsap.to(".tecnologia", {
            scrollTrigger: {
              trigger: ".tecnologia",
              scrub: true,
              start: "top center",
              end: "top 50px "
            },
            x: 0,
        });


    }, []); 

  return (
    <>
    <Content id='about'>
        <Side>
            <div className='tecnologia'>
                <h3>Tecnologia</h3>
            </div>
        </Side>
        <Side2>
            <div className='design'>
                <h3>Design</h3>
            </div>
            <div className='about'>
                <h2>Sobre <strong className='our'>nós</strong></h2>
                <h4>Somos uma empresa de tecnologia focada 
                    em trazer soluções de alto nível para 
                    o seu negócio conforme sua necessidade,
                    <strong className='shadow'> temos expertise nas tecnologia mais usadas
                    do mercado e sempre inovando com nossos projetos.</strong></h4>
            </div>

        </Side2>
    </Content>
    
    </>
    
  );
}

export default About;
