import React from 'react';
import { useState, useEffect } from 'react';
import { Content } from './style.js';
import Img from '../../assets/box.png';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';



export function Work() {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.to(".item", {
        scrollTrigger: {
          trigger: ".title-works",
          scrub: true,
          start: "top center",
          end: "top 50px "
        },
        scale: 1,
    });


}, []); 


  useEffect(() => {
    fetch('https://api.conjosa.com.br/api/projects-api/')
      .then(response => response.json())
      .then(data => setProjects(data))
      .catch(error => console.error('Erro ao buscar API:', error));
  }, []); 

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  return (
    <>
    <Content id="work">
        <div className='container'>
            <div className='our-works'>
                <h3>nossos trabalhos</h3>
            </div>
                <h2 className='title-works'>Selecionar <strong>Trabalho</strong></h2>
        </div>
        <Box className="item" sx={{ flexGrow: 1 }}>
          <Grid container spacing={8}>
            {projects.map((project, index) => (
              <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                <Item style={{boxShadow: "none",}}>
                  <a href={project.demo_link}>
                    <img className="box-img" src={Img} />
                  </a>
                  <h1 className='box-title'>{project.Project_title}</h1>
                  <h4 className='box-language'>{project.language_used}</h4>
                </Item>
              </Grid>
            ))}
          </Grid>
        </Box>
    </Content>
    </>
  );
}

export default Work;
