// App.js
import React, { useState, useEffect } from 'react';
import { Up, LinkInsta, LinkUp, LinkWhats, LinkMail, Modal} from './style.js';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Menu from '../components/Menu/index.js';
import Maximize from '../components/Maximize/index.js';
import Company from '../components/company/index.js';
import About from '../components/About/index.js';
import Work from '../components/Work/index.js';
import Optimize from '../components/Optimize/index.js';
import Stripe from '../components/Stripe/index.js';
import Tech from '../components/Tech/index.js';
import Contact from '../components/Contact/index.js';
import Footer from '../components/Footer/index.js';
import InstagramIcon from '@mui/icons-material/Instagram';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InterestsIcon from '@mui/icons-material/Interests';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';

export function App() {
  const [showLinks, setShowLinks] = useState(true);

  const handleClick = () => {
    setShowLinks(!showLinks);
  };










  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    nome: '',
    email: '',
    mensagem: ''
  });

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
  }, []);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Aqui você pode enviar os dados do formulário para onde precisar
    console.log(formData);
    // Resetar os campos do formulário após o envio
    setFormData({
      nome: '',
      email: '',
      mensagem: ''
    });
  };

  return (
    <>
      <Up href='#inicio'>
        <InterestsIcon onClick={handleClick} />
      </Up>
      <LinkInsta show={showLinks ? 1 : 0}>
        <a href='https://instagram.com/conjo.sa' target='_blank'>
          <InstagramIcon />
        </a>
      </LinkInsta>
      <LinkWhats show={showLinks ? 1 : 0}>
        <a href='https://wa.me/5585998186858' target='_blanck'>
          <WhatsAppIcon />
        </a>
      </LinkWhats>
      <LinkUp show={showLinks ? 1 : 0}>
        <a href='#inicio'>
          <ArrowUpwardIcon />
        </a>
      </LinkUp>
      <LinkMail show={showLinks ? 1 : 0} onClick={openModal}>
          <ForwardToInboxIcon />
      </LinkMail>
      <Menu />
      <Maximize />
      <Company />
      <About />
      <Work />
      <Optimize />
      <Stripe />
      <Tech />
      <Contact />
      <Footer />







      {showModal && (
        <Modal>
          <div className="modal">
            <h2>Entre em contato</h2>
            <form onSubmit={handleSubmit} className='form'>
              <div className='box-input'>
                <label htmlFor="nome">Nome:</label>
                <input
                  type="text"
                  id="nome"
                  name="nome"
                  value={formData.nome}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className='box-input'>
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className='box-input'>
                <label htmlFor="mensagem">Mensagem:</label>
                <textarea className='text-area'
                  id="mensagem"
                  name="mensagem"
                  value={formData.mensagem}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <button type="submit" className='btn-submit'>Enviar</button>
            </form>
            <button onClick={closeModal} className='close'>Fechar</button>
          </div>
        </Modal>
      )}
    </>
  );
}

export default App;
