import styled from 'styled-components';


export const Content = styled.section`
height: max-content;
width: 100%;
display: flex;
flex-diretion: row;
padding-bottom: 100px;


@media (max-width: 768px){
    flex-direction: column;
    height: max-content;
}
`;

export const Side = styled.div`
height: max-content;
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
position: relative;
padding-top: 60px;
padding-bottom: 60px;


@media (max-width: 768px){
    padding-top: 0px;
    padding-bottom: 0px;
}

h1{
    font-size: 5rem;
    font-family: 'Poppins', sans-serif;
}

@media (max-width: 768px){
    h1{
        font-size: 4rem;
    }
}

strong{
    -webkit-text-stroke: 2px black;
    color: transparent;
    font-family: 'Poppins', sans-serif;

}

.notebook{
    height: 400px;
    width: auto;
    opacity: 0;
    transform: scale(2);
    transition: 3s;
    position: absolute;
    right: 0;
    top: 50%;
}

@media (max-width: 768px){
    .notebook{
        height: 200px;
    }
}

`;


