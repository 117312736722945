import styled from 'styled-components';
import Back from '../../assets/back1.png';
import Back2 from '../../assets/back2.png';


export const Content = styled.section`
height: max-content;
width: 100%;
display: flex;
justify-content: center;
padding-top: 100px;
padding-bottom: 100px;
padding-left: 100px;
padding-right: 100px;
flex-direction: column;


@media (max-width: 768px){
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 50px;

}

.container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 40px;

}

.our-works{
    width: 200px;
    border: 1px solid #000;
    text-align: center;
    border-radius: 30px;
}

.our-works h3{
    font-size: 16px;
    margin: 12px;
}


.title-works{
    font-size: 80px;
    margin-top: 30px;

}

@media (max-width: 768px){
    .title-works{
        font-size: 60px;
        text-align: center;
    }
}

.title-works strong{
    -webkit-text-stroke: 2px black;
    font-weight: 400;
    color: transparent;
    font-family: 'Poppins', sans-serif;
}

.item{
    transform: scale(0.1);
    transition: 2s;
}


.box-img{
    height: 250px;
    width: auto;
    border-radius: 30px;
    transition: .3s;
}

.box-img:hover{
    transform: scale(1.1);
}


.box-title{
    color: #000;
    font-size: 25px;
}

.box-language{
    color: #777575;
    border: 1px solid #777575;
    display: inline-block;
    padding: 10px;
    border-radius: 20px;
}
`;

