import styled from 'styled-components';


export const Content = styled.section`
height: 300px;
width: 100%;
display: flex;
flex-direction: column;
padding-left: 50px;
padding-right: 50px;
gap: 50px;

.container{
    display: flex;
    flex.direction: row;
    width: 100%;
    justify-content: space-around;
    align-items: center;
}

@media (max-width: 768px){
    height: max-content;
    margin-top: 150px;

    .container{
        flex-direction: column;
        gap: 20px;
    }
}


.img-company{
    height: 60px;
    width: auto;
    transform: rotateY(200deg);
    transition: .5s;
}
`;


