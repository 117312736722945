import React from 'react';
import { ContentTech, Box, Box2 } from './style.js';
import Wave from '../../assets/wave.png';

export function Tech() {

  return (
    <>
    <ContentTech>
        <Box>
            <div>
                <h4>Software</h4>
            </div>
            <h2>
                A tecnologia certa para<br></br>
                <strong className='escala'>Escalabilidade e performance</strong>
            </h2>
            <h3>Soluções em desenvolvimento de software na <strong className='medida'>medida certa para sua empresa</strong></h3>
        </Box>
        <Box2>
            <img src={Wave} />
        </Box2>
    


    </ContentTech>
    
    </>
    
  );
}

export default Tech;
