import styled from 'styled-components';



export const Content = styled.section`
height: max-content;
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 30px;
padding-top: 100px;
padding-bottom: 100px;
background: #F2F2F2;


.contact{
    border: 1px solid #000;
    font-size: 18px;
    display: inline-block;
    padding: 15px;
    border-radius: 40px;
}

.lets{
    font-size: 70px;
    margin: 0;
    text-align: center;
}

button{
    text-decoration: none;
    color: #000;
    font-weight: 600;
    border: none;
    padding: 10px;
    border-bottom: 1px solid #000;
    transition: .3s;
}

button:hover{
    transform: scale(1.1);

}



`;

export const Modal = styled.div`
  /* Estilos para o modal */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* fundo semi-transparente */
  display: flex;
  align-items: center;
  justify-content: center;


  .modal {
    /* Estilos para o conteúdo do modal */
    background-color: #fff;
    width: 50%;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }

  @media (max-width: 1020px){
    .modal{
        width: 80%;
    }

  }


  
  .close {
    /* Estilos para o botão dentro do modal */
    background-color: #000;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: .3s;
  }
  
  .close:hover {
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
  }

  .form{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 50px;
  }

  .box-input{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
  }

  .box-input input{
    width: 100%;
    padding: 6px;
    border: 1px solid #000;
    border-radius: 5px;
    
  }

  .text-area{
    height: 100px;
    border-radius: 5px;
  }


  .btn-submit {
    /* Estilos para o botão dentro do modal */
    background-color: #000;
    color: #fff;
    border: none;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: .3s;
  }
  
  .btn-submit:hover {
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
  }

  
`;


