import styled from 'styled-components';



export const Content = styled.footer`
height: max-content;
width: 100%;
display: flex;
padding: 50px;
background: #fff;
flex-direction: column;


@media(max-width: 768px){
    padding: 20px;
    gap: 20px;
}

`;

export const Container = styled.div`
height: max-content;
width: 100%;
display: flex;
background: #000;
padding-top: 50px;
padding-bottom: 50px;
border-radius: 20px;
flex-direction: column;
justify-content: center;
align-items: start;
padding-left: 50px;
padding-right: 50px;

@media (max-width: 768px){
    gap: 20px;
}


li{
    color: #fff;
    list-style: none;
}

h3{
    color: #fff;
    font-size: 15px;
}

.links{
    display: flex;
    flex-direction: row;
    gap: 40px;
}

.endereco{
    width: 50%;
}

@media (max-width: 768px){

    


    .links{
        flex-direction: column;
    }

    .endereco{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}

ul{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

a{
    color: #fff;
    text-decoration: none;
}

`;


export const Cnpj = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;

  @media (max-width: 768px){
    padding: 20px;
    text-align: center;
}

`;

