import styled from 'styled-components';


export const Header = styled.header`
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  .icons{
    gap: 10px;
    display: flex;
    flex-direction: row;
  }

  svg{
    color: #000;
    font-size: 25px;
    transition: .3s;
  }

  svg:hover{
    transform: scale(1.1);
  }
  
`;

export const ImgLogo = styled.img`
height: 40px;
width: auto;
`;



export const SideMenu = styled.div`
  position: fixed;
  top: 0;
  left: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
  width: 250px;
  height: 100vh;
  background-color: #fff;
  transition: left 0.3s ease;
  ${({ isOpen }) => isOpen && `
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
    z-index: 999;
    width: 50%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;

  

    ul{
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    li{
      list-style: none;
    }

    a{
      color: #000;
      font-size: 30px;
      text-decoration: none;
      transition: .3s;
    }

    a:hover{
      transform: scale(1.1);
    }

    @media (max-width: 768px){
      a{
        font-size: 16px;
      }
    }

    h2{
      color:#fff;
      font-size: 16px;
    }
  `}
`;
