import React from 'react';
import {Side, Side2, Content} from './style.js';
import { useEffect } from 'react';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import Glass from '../../assets/glass.svg';


export function Optimize() {

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        gsap.to(".title-glass", {
            scrollTrigger: {
              trigger: ".content-glass",
              scrub: true,
              start: "top center",
              end: "top 50px "
            },
            scale: 1,
        });

        

    }, []); 

  return (
    <>
    <Content className='content-glass'>
        <Side>
            <h2 className='title-glass'>Otimize seu processo,<br />
            <strong className='stroke-glass'>Maximize seus resultados</strong></h2>
            <h4>Te ajudamos a atingir excelência operacional <strong className='font-glass'>para que seus objetivos sejam alcançados.</strong></h4>
        </Side>
        <Side2>
            <img className="img-glass" src={Glass} />
        </Side2>
    </Content>
    
    </>
    
  );
}

export default Optimize;
