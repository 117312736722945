import styled from 'styled-components';



export const Content = styled.section`
height: max-content;
width: 100%;
display: flex;

@media (max-width: 768px){
  flex-direction: column;
}

`;

export const Side = styled.div`
height: max-content;
width: 100%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
padding-left: 50px;


@media (max-width: 768px){
  padding: 20px;
  height: max-content;
}


h2{
    font-size: 70px;
    transform: scale(0.2);
    transition: 1s;
    margin-bottom: 1px;
}

@media (max-width: 768px){
  h2{
    font-size: 55px;
  }
}


h4{
    font-size: 20px;
    font-weight: 400;
}

.stroke-glass{
    -webkit-text-stroke: 2px black;
    color: transparent;
    font-family: 'Poppins', sans-serif;
}

.font-glass{
    color: #6F6F6F;
    font-weight: 400;
}
`;

export const Side2 = styled.div`
height: max-content;
width: 100%;
display: flex;

@media (max-width: 768px){
  flex-direction: column;
  height: max-content;
}

img{
    height: 800px;
    width: auto;
    transform: scale(0.1);
    transition: 2s;
    animation: floatAnimation 3s ease-in-out infinite; 
}

@media (max-width: 768px){
  img{
    height: 400px;
  }
}

@keyframes floatAnimation {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px); 
    }
    100% {
      transform: translateY(0);
    }
  }


`;



