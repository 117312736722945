import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Modal } from './style.js'; // Supondo que Content não seja necessário aqui

function Form() {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    nome: '',
    email: '',
    mensagem: ''
  });

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
  }, []);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Aqui você pode enviar os dados do formulário para onde precisar
    console.log(formData);
    // Resetar os campos do formulário após o envio
    setFormData({
      nome: '',
      email: '',
      mensagem: ''
    });
  };

  return (
    <>
      <button onClick={openModal}>Entre em contato</button>
    
      {showModal && (
        <Modal>
          <div className="modal">
            <h2>Entre em contato</h2>
            <form onSubmit={handleSubmit} className='form'>
              <div className='box-input'>
                <label htmlFor="nome">Nome:</label>
                <input
                  type="text"
                  id="nome"
                  name="nome"
                  value={formData.nome}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className='box-input'>
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className='box-input'>
                <label htmlFor="mensagem">Mensagem:</label>
                <textarea className='text-area'
                  id="mensagem"
                  name="mensagem"
                  value={formData.mensagem}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <button type="submit" className='btn-submit'>Enviar</button>
            </form>
            <button onClick={closeModal} className='close'>Fechar</button>
          </div>
        </Modal>
      )}
    </>
  );
}

export default Form;
