import styled from 'styled-components';


export const ContentTech = styled.section`
height: max-content;
width: 100%;
display: flex;
padding-top: 50px;
padding-bottom: 50px;
padding-left: 50px;
padding-right: 50px;

@media (max-width: 768px){
    flex-direction: column;
}

`;

export const Box = styled.div`
height: max-content;
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: start;



div{
    border: 1px solid #000;
    padding: 16px;
    border-radius: 40px;
}

h4{
    margin: 0;
}

h2{
    font-size: 70px;
    margin: 0;
}

@media (max-width: 768px){
    h2{
        font-size: 40px;
    }
}



.escala{
    -webkit-text-stroke: 2px black;
    color: transparent;
    font-family: 'Poppins', sans-serif;
}

h3{
    font-weight: 400;
}

.medida{
    color: #636363;
    font-weight: 400;
}
`;

export const Box2 = styled.div`
height: max-content;
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;



img{
    height: auto;
    width: 400px;
    border-radius: 30px;
    transition: .3s;
}

@media(max-width: 768px){
    img{
        width: 100%;
    }
}

img:hover{
    transform: scale(1.1);

}
`;

