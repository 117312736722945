import styled from 'styled-components';
import React, { useEffect } from 'react';
import { Content } from './style.js'; // Certifique-se de importar esses estilos corretamente
import Hapvida from '../../assets/hapvida.png';
import Tecla from '../../assets/tecla.png';
import Sucre from '../../assets/sucre.png';
import Bradesco from '../../assets/bradesco.png';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export function Company() {

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        gsap.to(".img-company", {
            scrollTrigger: {
              trigger: ".img-company",
            },
            rotateY: "0deg",
        });


    }, []); 

    return (
        <>
            <Content>
                <div>
                    <h1>Junte-se a essas empresas incríveis</h1>
                </div>
                <div className='container'>
                    <img className="img-company" src={Hapvida} alt="Hapvida" />
                    <img className="img-company" src={Tecla} alt="Tecla" />
                    <img className="img-company" src={Sucre} alt="Sucre" />
                    <img className="img-company" src={Bradesco} alt="Bradesco" />
                </div>
            </Content>
        </>
    );
}

export default Company;
