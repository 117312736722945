import React from 'react';
import { ContentStripe, Box } from './style.js';

export function Stripe() {

  return (
    <>
    <ContentStripe>
        <Box>
            <h2>Soluções robustas e escaláveis</h2>
            <div></div>
        </Box>    
        <Box>
            <h2>Seus projetos com a 
                tecnologia certa</h2>
                <div></div>
        </Box>    
        <Box>
            <h2>Agilidade do início
                ao fim</h2>
                <div></div>
        </Box>        
    </ContentStripe>
    
    </>
    
  );
}

export default Stripe;
